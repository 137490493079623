import React, { FunctionComponent, MouseEvent, MouseEventHandler } from "react"
import classNames from "classnames"
import { FormattedMessage } from "react-intl"
import { Icon } from "../icon/icon"
import { Avatar } from "../avatar/avatar"
import { getCustomAttributes } from "../../utils/shopify-buy"
import { AvatarList } from "../avatar/avatar-list"
import { SpinnerButton } from "../button/spinner-button"
import { ListItem, ListItemProps } from "./list-item"
import { ListItemProductSkeleton } from "./list-item-product-skeleton"
import styles from "./list.module.scss"
import { useShopifyProduct } from "@hooks/useShopifyProduct"
import { useNewCart } from "@contexts/newCartContext"
import {
  Availability,
  AddToCartMessgeId
} from "@features/store/product-availability-source"
import buildImageUrl from "@utils/imageUrl"
import { formatAuthorNames } from "@utils/author-names"
import { useImage } from "@hooks/use-image"
import { formatColorLanguage } from "@features/store/store-helpers"
import { ProductPrices } from "@components/card/product-prices"
import { TeacherBase } from "@src-types/teacher"

export type ListItemProductVariant = "regular" | "card"

export interface ListItemProductProps extends ListItemProps {
  productId?: string
  storefrontId?: string
  productHandle: string
  image?: string
  teachers: Array<TeacherBase>
  title: string
  type: string
  variant?: ListItemProductVariant
  onClick?: MouseEventHandler
  onSearchConversion?: () => void
}

export const ListItemProduct: FunctionComponent<ListItemProductProps> = ({
  className,
  disabled = false,
  storefrontId: defaultStorefrontId = "",
  productHandle,
  image,
  teachers,
  title,
  type,
  url,
  variant = "regular",
  onClick,
  onSearchConversion
}) => {
  const [{ context }, sendCartEvent] = useNewCart()
  const { itemsBeingAdded } = context

  const { isLoading, error, data } = useShopifyProduct(productHandle)

  const defaultVariant = data?.product?.variants?.[0]
  const imageUrl = image || defaultVariant?.image?.originalSrc || ""
  const transformedImageUrl = buildImageUrl(imageUrl || "", { width: 400 })
  const { loading: imageLoading, error: imageError } =
    useImage(transformedImageUrl)

  if (isLoading || imageLoading)
    return <ListItemProductSkeleton variant={variant} />

  if (!data || error) return null

  const shopProduct = data.product
  const availability = data.availability ?? Availability.unknown
  const price = data.price
  const compareAtPrice = data.compareAtPrice
  const storefrontId = defaultVariant?.storefrontId || defaultStorefrontId
  const itemIsBeingAdded = itemsBeingAdded.includes(storefrontId)

  const shouldAllowPurchase = [
    Availability.available,
    Availability.preorder,
    Availability.backorder
  ].includes(availability)

  const isRegularVariant = variant === "regular"

  const shouldEnableAddButton = Boolean(
    shouldAllowPurchase &&
      storefrontId &&
      productHandle &&
      price &&
      !isRegularVariant
  )

  // Original format title: "Leather-Like"
  // Business sees multiple "Leather-Like" titles and wants more added to show the difference between variants
  // Developer: I will add "more" :)
  // Business: ... \(..)/
  // Developer: ... :-0
  // Business: Why did you add this take it away at once
  // Developer: Yes my master... :'(

  // I'm keeping this here just incase.

  const overlineText = formatColorLanguage(
    type,
    undefined, // only include language for now.
    shopProduct?.language?.value
  )

  const shouldShowOverline = !isRegularVariant && overlineText
  const disableDropShadow = shopProduct?.disableDropShadow?.value === "true"

  const handleAddClick = async (e: MouseEvent) => {
    e.preventDefault()
    const customAttributes = getCustomAttributes(
      {
        ...shopProduct,
        title,
        availability,
        teachers: teachers?.map((t) => {
          return {
            name: `${t.shortName}`,
            slug: "",
            link: ""
          }
        })
      },
      {
        color: shopProduct?.color?.value,
        language: shopProduct?.language?.value,
        title: shopProduct?.format?.value,
        quantityAvailable: defaultVariant?.quantityAvailable,
        requiresShipping: defaultVariant?.requiresShipping
      }
    )

    sendCartEvent("ADD_LINE_ITEM", {
      data: {
        variantId: (defaultVariant?.storefrontId || storefrontId) as string,
        customAttributes
      }
    })
    onSearchConversion?.()
    e.stopPropagation()
  }

  const getAuthorName = (teachers: Array<TeacherBase>) => {
    if (!teachers?.length) return ""

    return teachers[0].shortName
  }

  return (
    <ListItem
      className={classNames(styles.listItemProduct, className, styles[variant])}
      disabled={disabled}
      url={url}
      dataTestId={`ProductListItem:${productHandle}`}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div
          className={styles.author}
          data-testid={`ProductListItemAuthor:${getAuthorName(teachers)}`}
        >
          {teachers?.length >= 1 ? (
            formatAuthorNames(teachers)
          ) : (
            <span className={styles.noAuthor}>{`\u2022 \u2022 \u2022`}</span>
          )}
        </div>
        <div className={styles.subheader}>
          <Icon variant="12-tag" className={styles.icon} />
          <span className={styles.type}>{type}</span>
        </div>
        <h2 className={styles.header}>{title}</h2>
        {teachers?.length === 1 && (
          <div className={classNames(styles.subheader, styles.authorAvatar)}>
            <Avatar
              image={teachers[0].image}
              name={teachers[0].shortName}
              showName
              size="xs"
            />
          </div>
        )}
        {teachers?.length > 1 && (
          <AvatarList
            listClassName={classNames(
              styles.subheader,
              styles.authorAvatar,
              styles.authorAvatarList
            )}
            itemClassName={styles.authorAvatarListItem}
            namesClassName={styles.authorAvatarListItem}
            size="xs"
            avatars={teachers}
            limit={4}
          />
        )}
        {!isRegularVariant && (
          <div className={styles.priceWithCartButtonDesktop}>
            <ProductPrices
              className={styles.prices}
              shopifyProduct={{ availability }}
              variant={{
                price,
                compareAtPrice,
                storefrontId
              }}
              onSearchConversion={onSearchConversion}
              processing={itemIsBeingAdded}
              shortButtonMessage={true}
            />
            {shouldEnableAddButton && (
              <SpinnerButton
                size="sm"
                variant="primary"
                className={styles.cartButton}
                processing={itemIsBeingAdded}
                onClick={handleAddClick}
                dataTestId={`AddToCartButton:${title}`}
              >
                <FormattedMessage id={AddToCartMessgeId} />
              </SpinnerButton>
            )}
          </div>
        )}
      </div>
      <div
        className={classNames(styles.side, {
          [styles.fixed]: variant === "card"
        })}
      >
        {shouldShowOverline && (
          <h4 className={styles.format}>{overlineText}</h4>
        )}
        {transformedImageUrl && !imageError ? (
          <img
            className={classNames(styles.image, {
              [styles.shadow]: !disableDropShadow
            })}
            src={transformedImageUrl}
            alt={title}
          />
        ) : (
          <span
            className={classNames(styles.image, styles.noImage)}
            style={{
              backgroundImage: `url(/assets/image-placeholders/list-logo.svg)`
            }}
          />
        )}
        <ProductPrices
          className={styles.priceWithCartButtonMobile}
          shopifyProduct={{ availability }}
          variant={{
            price,
            compareAtPrice,
            storefrontId
          }}
          onClick={variant === "card" ? handleAddClick : undefined}
          onSearchConversion={onSearchConversion}
          processing={itemIsBeingAdded}
          shortButtonMessage={true}
          buttonDataTestId={`AddToCartButton:${title}`}
          showAvailabilityMessages={false}
        />
      </div>
    </ListItem>
  )
}
